(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/helper-games/assets/javascripts/get-status-desc.js') >= 0) return;  svs.modules.push('/components/marketplace/helper-games/assets/javascripts/get-status-desc.js');

'use strict';

const {
  GAME_STATUS_DRAW_CLOSED,
  GAME_STATUS_FINISHED,
  GAME_STATUS_SUBMITTABLE,
  GAME_STATUS_SUBMITTED,
  GAME_STATUS_NOT_ENOUGH_SOLD,
  GAME_STATUS_SOLD_OUT
} = svs.components.marketplaceData.gamesConstants;
const getStatusDesc = (status, hasPermissionToSubmit, preliminaryRowExists) => {
  switch (status) {
    case GAME_STATUS_FINISHED:
      return 'Spelet är avgjort';
    case GAME_STATUS_SOLD_OUT:
      return 'Spelet är slutsålt';
    case !hasPermissionToSubmit && GAME_STATUS_SUBMITTABLE:
      return 'Spelet är stängt för köp';
    case hasPermissionToSubmit && GAME_STATUS_SUBMITTABLE:
      return 'Lämna in';
    case GAME_STATUS_SUBMITTED:
      return 'Spelet är inlämnat';
    case GAME_STATUS_DRAW_CLOSED:
      return 'Spelet är återbetalt';
    case GAME_STATUS_NOT_ENOUGH_SOLD:
      return 'För få sålda';
    default:
  }
  if (preliminaryRowExists) {
    return 'Radskiss finns';
  }
  return '';
};

setGlobal('svs.components.marketplace.helpers.games.getStatusDesc', getStatusDesc);

 })(window);