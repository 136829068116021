(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/helper-games/assets/javascripts/get-sub-product-tag.js') >= 0) return;  svs.modules.push('/components/marketplace/helper-games/assets/javascripts/get-sub-product-tag.js');
"use strict";


const {
  productIds
} = svs.utils.products;
const {
  subProducts
} = svs.components.marketplace.helpers;
const productMappings = {
  [productIds.RACING]: subProductId => {
    const color = 'racing';
    const tag = subProducts.racing.description[subProductId];
    if (tag) {
      return {
        tag,
        color
      };
    }
    return null;
  }
};
const getSubProductTag = (productId, subProductId) => {
  const subProductMapper = productMappings[productId];
  if (subProductMapper) {
    return subProductMapper(subProductId);
  }
  return null;
};

setGlobal('svs.components.marketplace.helpers.games.getSubProductTag', getSubProductTag);

 })(window);